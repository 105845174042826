<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            v-if="$can('delete', 'training_schedule')"
            cols="12"
            class="d-flex align-items-end justify-content-end mb-1"
          >
            <b-button
              variant="primary"
              class="mr-1"
              @click="DeleteRows"
            >
              Delete Selected Course
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="6"
            md="6"
            class="
                d-flex
                align-items-center
                justify-content-start
                mb-1 mb-md-0
              "
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />

          </b-col>

          <b-col
            md="6"
            xl="6"
          >
            <!-- <b-row>
                <b-col md="8" xl="8">
                  <flat-pickr
                    v-model="date"
                    placeholder="Search by date"
                    class="form-control" />
                </b-col>
                <b-col md="1" xl="1">
                  <b-button variant="primary" @click="date = ''">clear</b-button>
                </b-col>
              </b-row> -->
          </b-col>

          <!-- Search -->
          <!-- Search -->
          <b-col
            cols="12"
            md="12"
          >
            <div class="d-flex align-items-center justify-content-end mt-2">
              <!-- <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..." /> -->

            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refCourseListTable"
        class="position-relative"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :items="coursesList"
        select-mode="multi"
        selectable
        show-empty
        empty-text="No matching records found"

        @row-selected="onRowSelected"
      >
        <!-- Column: Status -->
        <template #cell(online)="data">
          <span v-if="data.item.online == 0">Classic</span>
          <span v-else>Online</span>
        </template>
        <template #cell(discount)="data">
          <span v-if="!data.item.discount">NoDiscount</span>
          <span v-else>{{ data.item.discount }}</span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <a
            v-if="$can('delete', 'training_schedule')"
            role="button"
            @click="DeleteItem(data.item.id)"
          >
            <feather-icon icon="TrashIcon" />
          </a>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
          >
            <span class="text-muted">
              <!-- Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries -->
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
          >
          
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCategories"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,

  BButton,
  BTable,

  BPagination,

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref, watch, computed, reactive,
} from '@vue/composition-api'
import Vue from 'vue'

import store from '@/store'

// import useCousresList from './useCousresList'
// import userStoreModule from '../userStoreModule'

export default {
  components: {
    // useCousresList,

    BCard,
    BRow,
    BCol,

    BButton,
    BTable,

    BPagination,

    vSelect,

  },
  setup() {
    const coursesList = ref([])
    const meta = reactive({})
    const date = ref('')
    const perPageOptions = [100, 200]
    const refCourseListTable = ref(null)
    const perPage = ref(100)
    const totalCategories = ref(0)
    const currentPage = ref(1)
    const searchQuery = ref('')
    const sortOptions = [{ id: 'course_name', label: 'Course Name' }, { id: 'duration', label: 'Duration' }, { id: 'city', label: 'City' }, { id: 'category', label: 'Category' },

      { id: 'price', label: 'Price' }, { id: 'date', label: 'Date' }]
    const sort = ref('')
    const selectedRow = ref([])
    const onRowSelected = items => {
      selectedRow.value = items
    }
    const GetAllschedule = () => {
      const data = sort.value.toString().replace('[', '')
      store
        .dispatch('trainingcourses/getAlltrainingschedules', {
          sort: data,
          // "filter[date]": date.value,
          'filter[search]': searchQuery.value,
          per_page: perPage.value,
          page: currentPage.value,
        })
        .then(response => {
          coursesList.value = response.data.data
          totalCategories.value = response.data.meta.total
        })
    }
    const DeleteRows = () => {
      //   console.log("se", selectedRow.value);
      const data = []
      selectedRow.value.forEach(el => {
        data.push(el.id)
      })
      Vue.swal({
        icon: 'warning',
        title: 'Are You Sure?',
        confirmButtonText: 'YES',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then(result => {
        if (result.value) {
          //   console.log("data",data);
          store
            .dispatch('trainingcourses/DeletetrainingSchedule', data)
            .then(response => {
              GetAllschedule()
              Vue.swal({
                title: 'schedule Deleted ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }

    watch([currentPage, perPage, searchQuery, date, sort], () => {
      GetAllschedule()
    })
    const dataMeta = computed(() => {
      const localItemsCount = refCourseListTable.value
        ? refCourseListTable.value.localItems.length
        : 0
      return {
        from:
            perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalCategories.value,
      }
    })
    GetAllschedule()

    const DeleteItem = id => {
      Vue.swal({
        icon: 'warning',
        title: 'Are You Sure?',
        confirmButtonText: 'YES',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then(result => {
        if (result.value) {
          store.dispatch('trainingcourses/DeletetrainingSchedule', id).then(response => {
            GetAllschedule()
            Vue.swal({
              title: 'Schedule Deleted ',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
        }
      })
    }
    const tableColumns = [
      { key: 'id', label: 'ID' },
      { key: 'training_course.name', label: 'Course Name' },

      { label: ' City', key: 'city' },
      { key: 'training_course.duration', label: 'Duration' },
      { key: 'date', label: 'Date' },
      { key: 'training_course.price.price', label: 'Price Without Discount' },

      { key: 'actions' },
    ]
    const selectAllRows = () => {
      refCourseListTable.value.selectAllRows()
    }
    return {
      tableColumns,
      coursesList,
      DeleteItem,
      GetAllschedule,
      meta,
      perPageOptions,
      totalCategories,
      currentPage,
      refCourseListTable,
      date,
      perPage,
      dataMeta,
      onRowSelected,
      selectedRow,
      DeleteRows,
      sortOptions,
      sort,
      searchQuery,
      selectAllRows,
    }
  },
}
</script>

  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  .col-md-1.col-xl-1 {
    margin-left: -7%;
  }
  </style>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
